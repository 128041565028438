import {pointsCalculator} from "./index";

export function getLiveData() {
    $.ajax({
        type: "GET",
        url: "livedata",
        cache: false,
        success: function (data) {
            updateMatchData(data);
        },
        error: function (data) {
        }
    });
}

let livescores = {};

function updateLivePoints(matchId, homeScore, awayScore) {
    let bet = $('#bet');
    if (bet.length > 0 && bet.data("home") !== "" && bet.data("away") !== "") {
        pointsCalculator(homeScore, awayScore, bet.data("home"), bet.data("away"), function (data) {
            $('#live-points').addClass("live");
            $('#live-points-data').html(data["points"]);
        });
    }
    livescores[matchId]["home"] = homeScore;
    livescores[matchId]["away"] = awayScore;
}

function updateMatchData(data) {
    let liveIds = [];
    data.forEach(function (match) {
        if (!livescores[match['match']]) {
            livescores[match['match']] = {home: null, away: null};
        }
        liveIds.push(match['match']);
        let matchObject = $(".match-" + match['match']);
        if (matchObject.length > 0) {
            let labelledBy = matchObject.parentsUntil('#round-tabs-content').last().attr("aria-labelledby");
            $("#" + labelledBy).addClass('live');
            if (!matchObject.hasClass("live")) {
                matchObject.addClass("live");
                if (location.pathname === "/match") {
                    setTimeout(function () {
                        location.reload();
                    }, 1500);
                }
            }

            let homeGoals = matchObject.find('.match-result .home-goals');
            let awayGoals = matchObject.find('.match-result .away-goals');
            if (match['penaltyHome'] > -1 && match['penaltyAway'] > -1) {
                let totalHome = match['scoreHome'] + match['penaltyHome'];
                let totalAway = match['scoreAway'] + match['penaltyAway'];
                matchObject.addClass("additional");
                homeGoals.html(totalHome);
                awayGoals.html(totalAway);
                matchObject.find('.match-result .result-additional .home-additional').html(match['scoreHome']);
                matchObject.find('.match-result .result-additional .away-additional').html(match['scoreAway']);
                if (livescores[match['match']]["home"] !== totalHome
                    || livescores[match['match']]["away"] !== totalAway) {
                    updateLivePoints(match['match'], totalHome, totalAway);
                }
            } else {
                homeGoals.html(match['scoreHome']);
                awayGoals.html(match['scoreAway']);
                if (livescores[match['match']]["home"] !== match['scoreHome']
                    || livescores[match['match']]["away"] !== match['scoreAway']) {
                    updateLivePoints(match['match'], match['scoreHome'], match['scoreAway']);
                }
            }

            /*
            const homeTeamName = match['homeTeam'];
            const awayTeamName = match['awayTeam'];
            const oldHomeScore = matchObject.data("scoreHomeOld");
            const oldAwayScore = matchObject.data("scoreAwayOld");
            if (match['scoreHome'] > oldHomeScore && match['scoreHome'] > 0) {
                notify(buildNotificationString(homeTeamName, awayTeamName, match['scoreHome'], match['scoreAway'], oldHomeScore, oldAwayScore, true));
            }
            if (match['scoreAway'] > oldAwayScore && match['scoreAway'] > 0) {
                notify(buildNotificationString(homeTeamName, awayTeamName, match['scoreHome'], match['scoreAway'], oldHomeScore, oldAwayScore, false));
            }
            if (match['penaltyHome'] > matchObject.data("penaltyHomeOld") && match['penaltyHome'] > 0) {
                notify(homeTeamName + " erzielt ein Tor zum " + match['penaltyHome'] + ":" + match['penaltyAway'] + " im Elfmeterschießen");
            }
            if (match['penaltyAway'] > matchObject.data("penaltyAwayOld") && match['penaltyAway'] > 0) {
                notify(awayTeamName + " erzielt ein Tor zum " + match['penaltyHome'] + ":" + match['penaltyAway'] + " im Elfmeterschießen");
            }
            */

            let liveStringText;
            if (match["phase"] === "HALFTIME") {
                liveStringText = "Halbzeit";
            } else if (match["phase"] === "END_SECOND_HALF") {
                liveStringText = "Ende 2. Hälfte";
            } else if (match["phase"] === "EXTRA_HALFTIME") {
                liveStringText = "Pause Verlängerung";
            } else if (match["phase"] === "PENALTY" || match["phase"] === "END_PENALTY") {
                liveStringText = "Elfmeterschießen";
            } else if (match["phase"] === "FULL_TIME") {
                liveStringText = "Ende";
            } else if (match["phase"] !== "NOT_PLAYING" && match["minuteExtra"] > 0) {
                liveStringText = match["minute"] + "+" + match["minuteExtra"] + "'";
            } else if (match["phase"] !== "NOT_PLAYING" && match["minute"] > 0) {
                liveStringText = match["minute"] + "'";
            } else {
                liveStringText = "LIVE";
            }
            matchObject.find('.live-string span, .live-string p').html(liveStringText);
            matchObject.data("scoreHomeOld", match['scoreHome']);
            matchObject.data("scoreAwayOld", match['scoreAway']);
            matchObject.data("penaltyHomeOld", match['penaltyHome']);
            matchObject.data("penaltyAwayOld", match['penaltyAway']);
        }
    });
    $('.match-list-entry.live, .match-details.live').each(function () {
        let id = parseInt($(this).attr('id').split("match-")[1]);
        if (liveIds.indexOf(id) === -1) {
            $(this).removeClass('live');
            if (location.pathname === "/match") {
                setTimeout(function () {
                    location.reload();
                }, 1500);
            } else {
                // remove live state from tab; if another match is still live, the next update will add the class anyway,
                // thus no search for other live matches in this tab is really necessary
                let labelledBy = $(this).parentsUntil('#round-tabs-content').last().attr("aria-labelledby");
                $("#" + labelledBy).removeClass('live');
            }
        }
    });
}

function buildNotificationString(homeTeam, awayTeam, scoreHome, scoreAway, scoreHomeOld, scoreAwayOld, homeGoal) {
    let string;
    if (scoreHomeOld === scoreAwayOld) {
        if (homeGoal) {
            string = homeTeam + " geht " + scoreHome + ":" + scoreAway + " in Führung gegen " + awayTeam;
        } else {
            string = awayTeam + " geht " + scoreHome + ":" + scoreAway + " in Führung gegen " + homeTeam;
        }
    } else if (scoreHome === scoreAway) {
        if (homeGoal) {
            string = "TOR! " + homeTeam + " gelingt der Ausgleich gegen " + awayTeam + " zum " + scoreHome + ":" + scoreAway;
        } else {
            string = "TOR! " + awayTeam + " gelingt der Ausgleich gegen " + homeTeam + " zum " + scoreHome + ":" + scoreAway;
        }
    } else if (scoreHomeOld > scoreAwayOld && homeGoal) {
        string = "TOR! " + homeTeam + " baut die Führung gegen " + awayTeam + " auf " + scoreHome + ":" + scoreAway + " aus.";
    } else if (scoreHomeOld < scoreAwayOld && !homeGoal) {
        string = "TOR! " + awayTeam + " baut die Führung gegen " + homeTeam + " auf " + scoreHome + ":" + scoreAway + " aus.";
    } else if (scoreHome === scoreAway + 1 && !homeGoal) {
        string = "TOR! " + awayTeam + " erzielt den Anschlusstreffer gegen " + homeTeam + ". Es steht " + scoreHome + ":" + scoreAway;
    } else if (scoreHome + 1 === scoreAway && homeGoal) {
        string = "TOR! " + homeTeam + " erzielt den Anschlusstreffer gegen " + awayTeam + ". Es steht " + scoreHome + ":" + scoreAway;
    } else {
        if (homeGoal) {
            string = "Tor für " + homeTeam;
        } else {
            string = "Tor für " + awayTeam;
        }
        string += "!<br>Im Spiel " + homeTeam + " gegen " + awayTeam + " steht es jetzt " + scoreHome + ":" + scoreAway;
    }

    return string;
}

function notify(text) {
    const notification = $('.notification');
    notification.find('.notification-text').html(text);
    notification.addClass('show');
    setTimeout(function () {
        notification.removeClass('show');
    }, 7500);
}