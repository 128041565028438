const validation_field_events = "input change paste";

// form validation error messages
const username_contains_space = "Benutzername darf keine Leerzeichen enthalten";
const username_too_short = "Benutzername muss mindestens 4 Zeichen lang sein";
const username_too_long = "Benutzername darf höchstens 4 Zeichen lang sein";
const username_taken = "Bitte wähle einen anderen Benutzernamen";
const mail_invalid = "Keine gültige E-Mail-Adresse";
const mail_taken = "Bitte wähle eine andere E-Mail-Adresse";
const password_too_short = "Passwort muss mindestens 8 Zeichen lang sein";
const password_no_match = "Passwort-Bestätigung stimmt nicht überein";

//regex to check if email valid
const email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const username_regex = /^[^\s]+$/;

window.addEventListener('load', function () {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    let forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    Array.prototype.filter.call(forms, function (form) {
        form.addEventListener('submit', function (event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        }, false);
    });
}, false);

$(function () {
    const signUpForm = $('#signup-form');
    signUpForm.find('input#username-signup').on(validation_field_events, function() {
        if (!username_regex.test($(this).val())) {
            setFieldInValid($(this), username_contains_space);
        } else if ($(this).val().length < 4) {
            setFieldInValid($(this), username_too_short);
        } else if ($(this).val().length > 32) {
            setFieldInValid($(this), username_too_long);
        } else {
            checkRegistrationWithServer($(this), "username", username_taken);
        }
        markFieldValidated($(this));
    });

    setupPasswordFormValidation(signUpForm);

    signUpForm.find('input#email-signup').on(validation_field_events, function() {
        if (!email_regex.test($(this).val())) {
            setFieldInValid($(this), mail_invalid);
        } else {
            checkRegistrationWithServer($(this), "email", mail_taken);
        }
        markFieldValidated($(this));
    });

    setupPasswordFormValidation($('#change-password-form'));
});

// form validation helper functions:

function setupPasswordFormValidation(form) {
    form.find('input#password-signup').on(validation_field_events, function () {
        if ($(this).val().length < 8) {
            setFieldInValid($(this), password_too_short);
        } else {
            setFieldValid($(this));
            const confirm = form.find('input#password-confirm-signup');
            if ($(this).val() === confirm.val()) {
                setFieldValid(confirm);
            }
        }
        markFieldValidated($(this));
    });

    form.find('input#password-confirm-signup').on(validation_field_events, function () {
        if ($(this).val() === form.find('input#password-signup').val()) {
            setFieldValid($(this));
        } else {
            setFieldInValid($(this), password_no_match);
        }
        markFieldValidated($(this));
    });
}

function checkRegistrationWithServer(field, data_field, error_message) {
    setFieldValid(field);
    let data = {};
    data[data_field] = field.val();
    $.ajax({
        type: "POST",
        url: 'checkregistration',
        data: data,
        cache: false,
        success: function (data) {
            if (data["valid"]) {
                setFieldValid(field);
            } else {
                setFieldInValid(field, error_message);
            }
        },
        error: function () {
            setFieldValid(field);
        }
    });
}

function markFieldValidated(field) {
    setTimeout(function (elem) {
        elem.parent().addClass("was-validated")
    }, 1000, field);
}

function setFieldValid(field) {
    setFieldInValid(field, "");
}

function setFieldInValid(field, error) {
    field.get(0).setCustomValidity(error);
    field.next().html(error);
}