let heightMap = new Map();
let affixElementsActive = new Set();


$(document).ready(function () {

    let toggleAffix = function (affixElement, scrollElement, wrapper) {
        let currentHeight = affixElement.outerHeight();
        let affixActive = affixElement.hasClass("affix");
        if (!affixActive) {
            heightMap.set(affixElement, currentHeight);
        }
        let height = heightMap.get(affixElement),
            top = wrapper.offset().top;
        let heightSum = 0;
        for(let aElement of affixElementsActive) {
            if (aElement === affixElement) {
                continue;
            }
            heightSum += aElement.outerHeight();
        }

        let scrollTop = scrollElement.scrollTop();
        if (!wrapper.is(":hidden") && (scrollTop + heightSum) >= top) {
            wrapper.height(height);
            affixElement.addClass("affix");
            if (!affixElementsActive.has(affixElement)) {
                affixElementsActive.add(affixElement);
                affixElement.css({top: heightSum});
            }
        } else if (affixActive) {
            affixElement.removeClass("affix");
            wrapper.height('auto');
            if (affixElementsActive.has(affixElement)) {
                affixElementsActive.delete(affixElement)
                affixElement.css({top: 0});
            }
        }
    };

    $('[data-toggle="affix"]').each(function () {
        let ele = $(this),
            wrapper = $('<div class="affix-wrapper"></div>');

        ele.before(wrapper);
        $(window).on('scroll resize', function () {
            toggleAffix(ele, $(this), wrapper);
        });

        // init
        toggleAffix(ele, $(window), wrapper);
    });
});