$(function () {
    $('form.comment-form').submit(function (event) {
        let postData = {
            'comment': $(this).find("textarea[name='comment']").val(),
            'csrf-token': $(this).find("input[name='csrf-token']").val()
        };
        $.ajax({
            type: "POST",
            url: "addcomment",
            data: postData,
            encode: true,
            cache: false,
            success: function () {
                getNewComments();
            },
            error: function () {
            }
        });
        event.preventDefault();
        this.reset();
    });
});

// hide list of usernames if no match was found
$("#comment-input").on('tribute-no-match', function () {
    $('.tribute-container').hide();
});

export function appendUsersToTribute(tribute) {
    $.ajax({
        url: 'getusers',
        dataType: 'json',
        success: function (data) {
            tribute.append(0, data);
        }
    });
}

export function getNewComments() {
    const newest = $('ul.comment-list').data("newest");
    $.ajax({
        type: "GET",
        url: "newcomments",
        data: {newest: newest},
        cache: false,
        success: function (data) {
            if (data.length > 0) {
                if (!$('.row-offcanvas').hasClass("active")) {
                    $('.btn-sidebar-open .comment-indicator').show();
                }
                const list = $('ul.comment-list');
                data.forEach(function (comment) {
                    const id = "comment-" + comment['id'];
                    if (document.getElementById(id) == null) {
                        list.data("newest", comment['id']); // update newest id
                        addCommentToList(comment);
                    }
                });
            }
        },
        error: function (data) {
        }
    });
}

function addCommentToList(comment) {
    const options = {hour: '2-digit', minute: '2-digit'};
    const dateOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
    const date = new Date(comment['date']);
    const list = $('ul.comment-list');
    const newId = "comment-" + comment['id'];
    if (parseInt(comment['user']) === 0) {
        list.prepend("<li class='comment mt-1'><div class='media-body p-1 p-lg-2 text-muted small'>gelöschter Kommentar</li>");
    } else {
        list.prepend("<li id='" + newId + "' class='hidden comment mt-1'>" +
            "<div class='media-body p-1 p-lg-2" + (comment['isown'] ? " own" : "") + "'>" + comment['comment'] +
            "<p class='text-right'><span class='text-muted'><a href='profile?id=" + comment['user'] + "'>" +
            comment['name'] + "</a> am " + date.toLocaleDateString('de-DE', dateOptions) + " um " + date.toLocaleTimeString('de-DE', options) +
            "</span></p></div></li>");
    }
    $("#" + newId).slideToggle();
}