const Highcharts = require('highcharts/highcharts');
window.Highcharts = Highcharts;

require('jquery-circle-progress');

const circleProgressElements = $('.circle-progress');
circleProgressElements.circleProgress({
    startAngle: -Math.PI * 0.5,
    size: 100,
    fill: '#569E3D',
    thickness: 8,
});

circleProgressElements.filter(".percentage").circleProgress({}).on('circle-animation-progress', function (event, progress, stepValue) {
    let percent = 100 * stepValue;
    $(this).find('strong').html(parseInt(percent) + '<i>%</i>');
});

circleProgressElements.filter(":not(.percentage)").circleProgress({}).on('circle-animation-progress', function (event, progress, stepValue) {
    let val = +(stepValue * $(this).data("max")).toFixed(2);
    $(this).find('strong').html(val);
});

$(function() {
    let indexOfCategory = {};
    let categoryNames = [];

    const history = $("#rank-history");
    if (history.length > 0) {
        let rankHistoryOptions = {
            chart: {
                backgroundColor: null,
                type: 'spline'
            },
            title: {
                text: null,
            },
            xAxis: {
                type: 'category',
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                min: 0.8,
                softMax: history.data("num-users"),
                startOnTick: false,
                endOnTick: false,
                tickInterval: 1,
                allowDecimals: false,
                reversed: true,
                title: {
                    text: null,
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }]
            },
            legend: {
                enabled: true,
            },
            tooltip: {
                useHTML: true,
                shared: true,
                hideDelay: 1000,
                style: {
                    pointerEvents: 'auto'
                },
                formatter: function () {
                    let s = "Platzierung nach dem Spiel:<br>" + categoryNames[this.points[0].key] + "<br>";

                    $.each(this.points, function () {
                        s += "<span style='color:" + this.color + "'>\u25CF</span> " + this.series.name + ": <b>Platz " + this.y + "</b><br>";
                    });

                    return s;
                },
            },
            series: [{
                name: history.data("user-name"),
                data: [],
                color: history.data("color")
            }, {
                name: "Du",
                data: [],
                color: history.data("own-color")
            }]
        };

        let historyURL = 'getrankhistory?id=' + history.data("user");
        if (history.data("own") !== history.data("user")) {
            historyURL += "," + history.data("own");
        }
        $.ajax({
            url: historyURL,
            dataType: 'json',
            success: function (data) {
                data['matches'].forEach(function (category, index) {
                    indexOfCategory[category['key']] = index;
                    categoryNames[category['key'].toString()] = category['name']
                });
                rankHistoryOptions.xAxis.categories = data['matches'].map(x => x['key']);
                $.each(data['history'], function (index, userHistory) {
                    let historyData = userHistory['history'].map(x => {
                        return {x: indexOfCategory[x['match']], y: x['rank']};
                    });
                    historyData.sort(function (a, b) {
                        return a.x - b.x;
                    });
                    if (userHistory['user'] === history.data("user")) {
                        rankHistoryOptions.series[0].data = historyData;
                    } else if (userHistory['user'] === history.data("own")) {
                        rankHistoryOptions.series[1].data = historyData;
                    }
                });
                if (history.data("own") === history.data("user")) {
                    rankHistoryOptions.series.splice(1, 1);
                }
                Highcharts.chart('rank-history', rankHistoryOptions);
            }
        });
    }

    const championBetChart = $('#champion-bet-chart');
    if (championBetChart.length > 0) {
        let championBetChartOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: null
            },
            credits: false,
            title: {
                text: null
            },
            tooltip: {
                pointFormat: '{point.percentage:.1f} % ({point.y} Tipps)'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    borderWidth: null,
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [{
                name: 'Team',
                colorByPoint: true,
                data: []
            }]
        };
        $.ajax({
            url: 'getchampionbetstats',
            dataType: 'json',
            success: function (data) {
                $.each(data, function (index, value) {
                    championBetChartOptions.series[0].data.push({"name": index, "y": value})
                });
                Highcharts.chart('champion-bet-chart', championBetChartOptions);
            }
        });
    }

    const pointDistributionChart = $('#user-point-distribution-chart');
    if (pointDistributionChart.length > 0) {
        let pointColors = {0: '#b3b3b3', 2: '#dc3545', 10: '#fd7e14', 12: '#ffc107', 15: '#7df048', 20: '#28a745'};
        let pointDistributionChartOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: null,
                height: '100%',
            },
            credits: false,

            title: {
                text: null
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<b>{point.name} Punkte:</b><br> {point.y} Tipps - {point.percentage:.1f} %'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    borderWidth: null,
                    size: "100%",
                    dataLabels: {
                        enabled: true,
                        distance: "-30%",
                        format: '<b>{point.name}</b>',
                    }
                }
            },
            series: [{
                name: 'Punkteverteilung',
                data: []
            }]
        };
        let distribution = pointDistributionChart.data("points");
        for (let key in distribution) {
            let value = distribution[key] === 0 ? null : distribution[key];
            pointDistributionChartOptions.series[0].data.push({name: key, y: value, color: pointColors[key]})
        }

        Highcharts.chart('user-point-distribution-chart', pointDistributionChartOptions);
    }

    barStatistics("match-average-points-chart", "Pro Spiel im Schnitt erzielte Punkte", "Im Durchschnitt erzielte Punkte", "match?id=");
    barStatistics("match-average-goals-chart", "Pro Spiel im Schnitt getippte Tore", "Im Durchschnitt getippte Tore", "match?id=");
    barStatistics("team-average-points-chart", "Pro Team im Schnitt erzielte Punkte", "Im Durchschnitt erzielte Punkte", "team?id=");
    barStatistics("team-average-goals-chart", "Pro Team im Schnitt getippte Tore", "Im Durchschnitt getippte Tore", "team?id=");
});

let barStatsOptions = {
    chart: {
        type: 'bar',
        backgroundColor: null,
    },
    title: {
        text: null,
        enabled: false
    },
    xAxis: {
        type: 'categories',
        title: {
            text: null
        },
        categories: []
    },
    yAxis: {
        title: {
            text: '',
        }
    },
    tooltip: {
        headerFormat: '<span>{point.key}</span><br/>',
        pointFormat: '',
    },
    plotOptions: {
        bar: {
            dataLabels: {
                formatter: function () {
                    return roundToTwo(this.y);
                },
                enabled: true
            },
            borderColor: null,
            point: {
                events: {
                    click: function () {
                        location.href = this.options.link;
                    }
                }
            },
            cursor: "pointer",
        }
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    series: [{data: []}],
};

function barStatistics(chartContainerId, title, tooltipText, link) {
    barStatsOptions.xAxis.categories = [];
    barStatsOptions.series[0].data = [];

    let chart = $('#' + chartContainerId);
    if (chart.length > 0) {
        let options = barStatsOptions;
        options.yAxis.title.text = title;
        options.tooltip.pointFormat = "<b>" + tooltipText + ": {point.y}</b><br/>";

        let statsData = chart.data("stats");
        statsData.forEach(function (item) {
            options.xAxis.categories.push(item["title"]);
            options.series[0].data.push({
                y: item["value"],
                link: link + item["id"],
                color: item["flop"] ? "#8c0000" : "#008000"
            });
        });
        Highcharts.chart(chartContainerId, options);
    }
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}