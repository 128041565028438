import * as Sentry from '@sentry/browser';

Sentry.init({dsn: __SENTRY_DSN__});

/*!
 * Bootstrap JS
 */
import 'bootstrap';

/*!
 * Bootstrap CSS and custom styles
 */
import '../scss/main.scss';

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 */
import './ie10-viewport-bug-workaround.js';
import Tribute from "tributejs";
//require("jquery-touchswipe");
require("jquery-tablesort");

import './affix';
import './ticker.js';
import './live.js';
import './form-validation.js';
import './charts.js';
import {appendUsersToTribute, getNewComments} from "./comments.js";
import {getLiveData} from "./live";

//require('datatables.net');
//require('datatables.net-bs4');

/*
$.extend( $.fn.dataTable.defaults, {
    searching: false,
    paging:  false,
    info: false
});

$(document).ready( function () {
    $('.rank-table').DataTable({
        orderMulti: true
    });
});*/

$(document).ready(function () {
    // off-canvas toggle
    $('[data-toggle="offcanvas"]').click(function () {
        $('.row-offcanvas').toggleClass('active');
        $(".btn-sidebar-open").toggleClass('d-none');
        $('.btn-sidebar-open .comment-indicator').hide();
    });

    //highlight nav element
    const currentPageUrl = location.href;
    $('.navbar-nav li a').each(function () {
        $(this).toggleClass('active', this.href.length > 0 && currentPageUrl.startsWith(this.href));
    });

    if (location.pathname.startsWith("/matchlist")) {
        //let anchor = window.location.hash;
        let pos = _get("pos");
        let matchObject = $('#' + pos);
        if (matchObject.length) {
            // find tab content object and its corresponding tab nav link
            let labelledBy = matchObject.parent().parent().first().attr("aria-labelledby");
            $("#" + labelledBy).tab('show');
            scrollIntoViewIfNeeded(matchObject[0]);
        }
    }
    $('thead th.sort-float').data(
        'sortBy',
        function(th, td, tablesort) {
            return parseFloat(td.text());
        }
    );
    $('table.sortable').tablesort();

});

function scrollIntoViewIfNeeded(target) {
    if (target.getBoundingClientRect().bottom > window.innerHeight) {
        target.scrollIntoView();
    }

    if (target.getBoundingClientRect().top < 0) {
        target.scrollIntoView();
    }
}

$('#nav-login-form').submit(function (evt) {
    if (!$("#nav-login-form > [name='username']").val() && !$("#nav-login-form > [name='password']").val()) {
        evt.preventDefault();
        location.href = "loginpage";
    }
});

/*
$(".nav-tabs-container").swipe({
    //TODO: should only trigger switching the tab in the correct tab container
    swipeLeft: function (event, direction, distance, duration, fingerCount) {
        $(".nav-tabs li a.active").parent().next('li').find('a').tab('show');
    },
    swipeRight: function (event, direction, distance, duration, fingerCount) {
        $(".nav-tabs li a.active").parent().prev('li').find('a').tab('show');
    },
});
*/

let tribute = new Tribute({
    values: [],
    lookup: 'name',
    fillAttr: 'name'
});

$(function () {
    $(".clickable-row").click(function () {
        window.location = $(this).data("href");
    });
    if ($('ul.comment-list').length > 0) {
        tribute.attach(document.getElementById("comment-input"));
        getNewComments();
        setInterval(getNewComments, 3000);
        appendUsersToTribute(tribute);
    }
    if ($('.match-details').length > 0 || $('.match-list-entry').length > 0) {
        getLiveData();
        setInterval(getLiveData, 3000);
    }
    if ($(location).attr('pathname').startsWith("/loginpage") && $(location).attr('search').startsWith("?register")) {
        $('#collapseTwo').collapse('show');
    }

    $('#version').prop('title', 'frontend built: ' + getBuildTime().toLocaleString("de-DE"));
    $('[data-toggle="tooltip"]').tooltip();

    $('.progress-bar').each(function () {
        $(this).width($(this).attr("aria-valuenow") + "%");
    });

    $(document.body).on("submit", "#points-calculator-form", function (event) {
        let homeResult = $("input[name='home-result']").val();
        let awayResult = $("input[name='away-result']").val();
        let homeBet = $("input[name='home-bet']").val();
        let awayBet = $("input[name='away-bet']").val();

        if ($.isNumeric(homeResult) && $.isNumeric(awayResult) && $.isNumeric(homeBet) && $.isNumeric(awayBet)) {
            pointsCalculator(homeResult, awayResult, homeBet, awayBet, function (data) {
                $("#points-result").text(data['points']);
            });
        } else {
            $("#points-result").text("");
        }

        event.preventDefault();
    });

    $(document.body).on("submit", "#bet-form", function (event) {
        let postData = {
            'id': matchID,
            'home-bet': $("input[name='home-bet']").val(),
            'away-bet': $("input[name='away-bet']").val(),
            'csrf-token': $(this).find("input[name='csrf-token']").val()
        };
        if ($(this).hasClass("knockout") && postData["home-bet"] === postData["away-bet"]) {
            $("#betSuccess").hide();
            $("#bet-error").show();
        } else {
            $.ajax({
                type: "POST",
                url: "bet",
                data: postData,
                dataType: "html",
                encode: true,
                cache: false,
                success: function () {
                    $("#bet-error").hide();
                    $("#betSuccess").show().delay(5000).fadeOut();
                },
                error: function (data) {
                    console.log(data);
                }
            });
        }
        event.preventDefault();
    });

    $('#champion-form').submit(function (event) {
        if ($(this).data("bet-points") > $(this).data("current-points")
            && $(this).data("current") !== $(this).find('select[name="champion"]').find(':selected').data("key")) {
            $('#champion-bet-modal').modal('show');
        } else {
            placeChampionBet($(this));
        }
        event.preventDefault();
    });

    $('#champion-confirm-form').submit(function (event) {
        placeChampionBet($('#champion-form'));
        $('#champion-bet-modal').modal('hide');
        event.preventDefault();
    });

    function placeChampionBet(formElement) {
        const dateOptions = {hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric'};
        let postData = {
            'champion': formElement.find("select[name='champion']").val(),
            'csrf-token': formElement.find("input[name='csrf-token']").val()
        };
        $.ajax({
            type: "POST",
            url: "betchampion",
            data: postData,
            encode: true,
            cache: false,
            success: function (data) {
                $("#betSuccess").fadeIn().delay(5000).fadeOut();
                const cbs = $('.champion-bet-status');
                cbs.removeClass('no-bet').removeClass('alert-info').addClass('alert-success').addClass('bet');
                $('.champion-bet-changed').removeClass('no-bet');
                cbs.find('.bet-possible-points').html(data['betPossiblePoints']);
                formElement.data("bet-points", data['betPossiblePoints']);
                formElement.data("current-points", data['currentPossiblePoints']);
                formElement.data("current", data["countryCode"]);
                cbs.find('img.bet-team').attr("src", "resources/flags/" + data['countryCode'] + ".svg");
                cbs.find('img.bet-team').attr("alt", data['countryName']);
                cbs.find('span.bet-team').html(data['countryName']);
                $('#champion-bet-change-date').html(new Date(data['changed']).toLocaleString('de-DE', dateOptions));
            }
        });
    }

    const accountSaveForm = $('#account-save-form');
    const params = $("#settings-params");
    accountSaveForm.submit(function (event) {
        const postData = {
            'save_account': $(this).find("input[name='as-check']").is(':checked'),
            'save_mail': $(this).find("input[name='ms-check']").is(':checked'),
            'csrf-token': $(this).find("input[name='csrf-token']").val()
        };
        if (accountSaveForm.hasClass("first-settings")) {
            postData['id'] = params.data("id");
            postData['token'] = params.data("token");
            postData['first_settings'] = true;
        }
        $.ajax({
            type: "POST",
            url: "saveaccsettings",
            data: postData,
            encode: true,
            cache: false,
            success: function () {
                if (accountSaveForm.hasClass("first-settings")) {
                    $("#account-save-form").slideToggle();
                    $("#reminder-settings").hide().removeClass("d-none").slideToggle();
                    $(".registration-progress > .progress-bar").attr("aria-valuenow", 75).css("width", "75%");
                } else {
                    accountSaveForm.find(".save-success").fadeIn().delay(5000).fadeOut();
                }
            }
        });
        event.preventDefault();
    });

    const reminderForm = $('#reminder-settings-form');
    reminderForm.submit(function (event) {
        const postData = {
            'enable_reminder': $(this).find("input[name='remind-check']").is(':checked'),
            'remind-time': $(this).find("input[name='remind-time']").val(),
            'csrf-token': $(this).find("input[name='csrf-token']").val()
        };
        if (reminderForm.hasClass("first-settings")) {
            postData['id'] = params.data("id");
            postData['token'] = params.data("token");
            postData['first_settings'] = true;
        }
        $.ajax({
            type: "POST",
            url: "saveremindersettings",
            data: postData,
            encode: true,
            cache: false,
            success: function () {
                if (reminderForm.hasClass("first-settings")) {
                    $("#reminder-settings-form").slideToggle();
                    $("#login-form-container").hide().removeClass("d-none").slideToggle();
                    $("#jumbotron-progress > h2").text("Fertig!");
                    $("#jumbotron-progress span#login-now-text").text("jetzt");
                    $(".registration-progress > .progress-bar").attr("aria-valuenow", 100).css("width", "100%");
                } else {
                    reminderForm.find(".save-success").fadeIn().delay(5000).fadeOut();
                }
            }
        });
        event.preventDefault();
    });

    $('#range-indicator').html(timeString(reminderForm.find("#remind-time").val()));

    // update range indicator string
    reminderForm.find("#remind-time").on("change mousemove input", function () {
        $('#range-indicator').html(timeString($(this).val()));
    });

    // hide / show reminder time input depending if reminder enabled/disabled
    reminderForm.find("#reminder-checkbox").on("change", function () {
        let container = reminderForm.find("#reminder-range-container");
        if ($(this).is(':checked')) {
            container.slideDown();
        } else {
            container.slideUp();
        }
    });
});

/**
 * function to convert minutes (integer value) into a string containing hours and minutes
 * @param minutes
 * @returns {string}
 */
function timeString(minutes) {
    minutes = parseInt(minutes);
    const hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;
    let str = minutes + " min";
    if (hours > 0) {
        str = hours + " h ";
        if (minutes > 0) {
            str += minutes + " min";
        }
    }
    return str;
}

/**
 * Function to retrieve a get parameter from the current document (using location.href)
 *
 * @param parameter {String} Key of the get parameter to retrieve
 */
function _get(parameter) {
    const reg = new RegExp('[?&]' + parameter + '=([^&#]*)', 'i');
    const string = reg.exec(window.location.href);
    return string ? string[1] : undefined;
}

function getBuildTime() {
    return new Date(__BUILD_TIME__);
}

/**
 *
 * @param homeResult {int}
 * @param awayResult {int}
 * @param homeBet {int}
 * @param awayBet {int}
 * @param callback {function}
 */
export function pointsCalculator(homeResult, awayResult, homeBet, awayBet, callback) {
    let data = {home_result: homeResult, away_result: awayResult, home_bet: homeBet, away_bet: awayBet};
    $.ajax({
        type: "GET",
        url: 'calculatepoints',
        data: data,
        success: function (data) {
            callback(data);
        },
        error: function () {
        }
    });
}
