let latestId = 0;
let template = "";
let eventContainer;
let matchElement;
let homeID;
let awayID;

const EventType = {
    START_PHASE: "START_PHASE",
    END_PHASE: "END_PHASE",
    EXTRA_TIME: "EXTRA_TIME",
    PAUSE: "PAUSE",
    RESUME: "RESUME",
    FOUL: "FOUL",
    YELLOW_CARD: "YELLOW_CARD",
    RED_CARD: "RED_CARD",
    RED_YELLOW_CARD: "RED_YELLOW_CARD",
    GOAL: "GOAL",
    SHOT_ON_GOAL: "SHOT_ON_GOAL",
    SHOT_WIDE: "SHOT_WIDE",
    SHOT_BLOCKED: "SHOT_BLOCKED",
    SAVE: "SAVE",
    PENALTY: "PENALTY",
    CORNER: "CORNER",
    FREE_KICK: "FREE_KICK",
    ASSIST: "ASSIST",
    OFFSIDE: "OFFSIDE",
    SUBSTITUTION: "SUBSTITUTION",
    WARNING: "WARNING",
};
Object.freeze(EventType);

const EventSubType = {
    MISS: "MISS",
    GOAL: "GOAL",
    PENALTY_GOAL: "PENALTY_GOAL",
    OWN_GOAL: "OWN_GOAL",
    FULL_TIME: "FULL_TIME"
}
Object.freeze(EventSubType);

$(document).ready(function () {
    if (typeof matchID !== 'undefined') {
        template = $("#event-template").html();
        eventContainer = $(".events-container").first();
        matchElement = $("#match-"+matchID).first();
        homeID = matchElement.data("home");
        awayID = matchElement.data("away");
        getNewEvents();
        if (matchElement.hasClass("live")) {
            setInterval(getNewEvents, 3000);
        }
    }
});

function processNewEvent(data) {
    let type = data["type"];
    let result = template.replace(/{type}/g, type);
    let timeStr = data["minute"] + "'";
    if (data["minuteExtra"] !== null && data["minuteExtra"] > 0) {
        timeStr += "+" + data["minuteExtra"];
    }
    result = result.replace(/{time}/g, timeStr);
    result = result.replace(/{eventID}/g, data["id"]);

    let heading = "";
    let text = "";
    let icon = "";
    let extraClasses = "";
    let primaryActor = data["primaryPlayer"] ? data["primaryPlayer"]["name"] : data["primaryTeam"] ? data["primaryTeam"]["name"] : "";
    if (data["primaryPlayer"] && data["primaryPlayer"]["position"] === "GOALKEEPER") {
        primaryActor += " <span title='Torwart'>(TW)</span>";
    }
    let secondaryActor = data["secondaryPlayer"] ? data["secondaryPlayer"]["shortName"] : data["secondaryTeam"] ? data["secondaryTeam"]["name"] : "";
    switch (type) {
        case EventType.END_PHASE:
        case EventType.START_PHASE:
            if (data["phase"] !== "PENALTY" && type === EventType.END_PHASE && data["subType"] === EventSubType.FULL_TIME) {
                heading = "Abpfiff!";
                text = "Der Schiedsrichter beendet das Spiel.";
                break;
            }
            text = type === EventType.END_PHASE ? "Ende" : "Beginn";
            text += " der ";
            switch (data["phase"]) {
                case "FIRST_HALF":
                    if (type === EventType.START_PHASE) {
                        heading = "Anpfiff";
                        text = "Das Spiel beginnt.";
                    } else {
                        text += "ersten Halbzeit";
                    }
                    break;
                case "SECOND_HALF":
                    text += "zweiten Halbzeit";
                    break;
                case "FIRST_EXTRA":
                    text += "ersten Hälfte der Verlängerung"
                    break;
                case "SECOND_EXTRA":
                    text += "zweiten Hälfte der Verlängerung"
                    break;
                case "PENALTY":
                    if (type === EventType.START_PHASE) {
                        text = "Das Elfmeterschießen beginnt."
                    } else {
                        text = "Das Elfmeterschießen ist zu Ende."
                    }
                    break;
                default:
                    return;
            }
            break;
        case EventType.PAUSE:
            text = "Das Spiel ist unterbrochen";
            break;
        case EventType.RESUME:
            text = "Das Spiel wird fortgesetzt";
            break;
        case EventType.YELLOW_CARD:
            text = "Gelbe Karte für <b>" + primaryActor + "</b>";
            icon = "<img src='resources/events/yellowcard.svg' alt=''>";
            break;
        case EventType.RED_YELLOW_CARD:
            text = "Gelb-Rot für <b>" + primaryActor + "</b>";
            icon = "<img src='resources/events/redyellowcard.svg' alt=''>";
            break;
        case EventType.RED_CARD:
            text = "Rote Karte für <b>" + primaryActor + "</b>";
            icon = "<img src='resources/events/redcard.svg' alt=''>";
            break;
        case EventType.GOAL:
            if (data["subType"] === EventSubType.OWN_GOAL) {
                heading = "Eigentor!"
                text = "<b>" + primaryActor + "</b> schießt ein Eigentor";
                icon = "<img src='resources/events/goal-own.svg' alt=''>";
            } else if (data["subType"] === EventSubType.PENALTY_GOAL) {
                heading = "Tor!"
                text = "<b>" + primaryActor + "</b> verwandelt den Straftoß";
                icon = "<img src='resources/events/goal-penalty.svg' alt=''>";
            } else {
                heading = "Tor!"
                if (data["primaryPlayer"]) {
                    text = "<b>" + primaryActor + "</b> trifft ";
                }
                text += "für " + data["primaryTeam"]["name"]

                icon = "<img src='resources/events/goal.svg' alt=''>";
            }

            break;
        case EventType.CORNER:
            text = "<b>" + primaryActor + "</b> tritt die Ecke für " + data["primaryTeam"]["name"];
            break;
        case EventType.SUBSTITUTION:
            heading = "Wechsel bei " + data["primaryTeam"]["name"];
            text = "<p class='mb-0 font-weight-bold'><img class='mr-2' src='resources/events/substitution-out.svg' alt=''> " + data["primaryPlayer"]["name"] + " </p>" +
                "<p class='mb-0 font-weight-bold'><img class='mr-2' src='resources/events/substitution-in.svg' alt=''> " + data["secondaryPlayer"]["name"] + "</p>";
            $('.lineup [data-player-id=' + data["primaryPlayer"]["id"] + ']').find('.lineup-player-substitution').removeClass('d-none');
            $('.lineup [data-player-id=' + data["secondaryPlayer"]["id"] + ']').find('.lineup-player-substitution').removeClass('d-none');
            break;
        case EventType.OFFSIDE:
            text = "<b>" + primaryActor + "</b> steht im Abseits.";
            break;
        case EventType.FOUL:
            text = "<b>" + primaryActor + "</b> begeht ein Foulspiel";
            if (secondaryActor.length > 0) {
                text += " an <b>" + secondaryActor + "</b>";
            }
            break;
        case EventType.FREE_KICK:
            text = "<b>" + primaryActor + "</b> schießt einen Freistoß für " + data["primaryTeam"]["name"];
            break;
        case EventType.SHOT_ON_GOAL:
            text = "<b>" + primaryActor + "</b> versucht es mit einem Torschuss.";
            break;
        case EventType.SHOT_WIDE:
            text = "<b>" + primaryActor + "</b> schießt, doch der Ball geht am Tor vorbei.";
            break;
        case EventType.SHOT_BLOCKED:
            text = "<b>" + primaryActor + "</b> schießt aufs Tor, doch der Ball wird abgewehrt.";
            break;
        case EventType.SAVE:
            if (primaryActor.length > 0) {
                text = "<b>" + primaryActor + "</b> wehrt den Schuss ab.";
            }
            break;
        case EventType.PENALTY:
            switch (data["subType"]) {
                case EventSubType.GOAL:
                    heading = "Tor!";
                    text = "<b>" + primaryActor + "</b> verwandelt den Elfmeter."
                    icon = "<img src='resources/events/goal-penalty.svg' alt=''>";
                    break;
                case EventSubType.MISS:
                    heading = "Elfmeter verschossen!"
                    text = "<b>" + primaryActor + "</b> verschießt den Strafstoß."
                    icon = "<img src='resources/events/penalty-miss.svg' alt=''>";
                    break;
                default:
                    return;
            }
            if (data["phase"] === "PENALTY") {
                extraClasses = "penalty-shootout";
            }
            break;
        // TODO case ASSIST
        default:
            return;
    }
    let side = "";
    result = result.replace(/{extra-classes}/g, extraClasses);
    result = result.replace(/{heading}/g, heading);
    result = result.replace(/{text}/g, text);
    result = result.replace(/{icon}/g, icon);
    let showIcon = icon !== "" ? "show" : "";
    result = result.replace(/{show-icon}/g, showIcon);
    if (data["primaryTeam"] !== null) {
        if (data["primaryTeam"]["id"] === homeID) {
            side = "home";
        } else {
            side = "away";
        }
    }
    result = result.replace(/{side}/g, side);
    let existingEvent = eventContainer.find('#event-' + data["id"]);
    if (existingEvent.length > 0) {
        existingEvent.replaceWith(result);
    } else {
        eventContainer.prepend(result);
    }
}

function deleteEvent(data) {
    let existingEvent = eventContainer.find('#event-' + data["id"]);
    existingEvent.remove();
}


function getNewEvents() {
    $.ajax ({
        type: "GET",
        url: "getevents",
        data: {
            id: matchID,
            latest: latestId
        },
        cache: false,
        success: function(data) {
            if (data["events"].length > 0) {
                latestId = data["events"][data["events"].length - 1]['id'];
            }
            data["events"].forEach(function(event) {
                processNewEvent(event);
            });
            data["deleted"].forEach(function (event) {
                deleteEvent(event);
            });
        },
        error: function(data) {
        }
    });
}